/* eslint-disable max-classes-per-file */
const defaults = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  method: "GET",
};

class ApiCallParams {
  public action: any;
  public method: any;
  public headers: any;
  public query: any;
  public responseType: any;
  public body: any;

  constructor(params) {
    const { action, method, headers, body, query, responseType = "json" } = params;

    this.action = action;
    this.method = method || defaults.method;
    this.headers = { ...defaults.headers, ...headers };
    this.query = query || {};
    this.responseType = responseType;

    if (body) {
      this.body = JSON.stringify(body);
    }
  }
}

class ApiPostCallParams extends ApiCallParams {
  constructor(params) {
    super({ ...params, method: "POST" });
  }
}

class ApiGetCallParams extends ApiCallParams {
  constructor(params) {
    super({ ...params, method: "GET" });
  }
}

class ApiGetBlobCallParams extends ApiGetCallParams {
  constructor(params) {
    super({ ...params, method: "GET", responseType: "blob" });
  }
}

class ApiPOSTBlobCallParams extends ApiPostCallParams {
  constructor(params) {
    super({ ...params, responseType: "blob" });
  }
}

class ApiPatchCallParams extends ApiCallParams {
  constructor(params) {
    super({ ...params, method: "PATCH" });
  }
}

class ApiPutCallParams extends ApiCallParams {
  constructor(params) {
    super({ ...params, method: "PUT" });
  }
}

class ApiDeleteCallParams extends ApiCallParams {
  constructor(params) {
    super({ ...params, method: "DELETE" });
  }
}

export {
  ApiCallParams,
  ApiPostCallParams,
  ApiGetCallParams,
  ApiPatchCallParams,
  ApiPutCallParams,
  ApiDeleteCallParams,
  ApiGetBlobCallParams,
  ApiPOSTBlobCallParams,
};
