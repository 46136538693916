export const isObjectEmpty = (obj: any) => {
  return !obj || Object.keys(obj).length === 0;
};

export const isNullOrUndefined = (value: any) => {
  return value === undefined || value === null;
};

export const cleanObj = (obj) => {
  if (!obj) return obj;
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const pick = function (obj: any, props: string[]) {
  // Make sure object and properties are provided
  if (!obj || !props) return obj;

  // Create new object
  const picked: any = {};

  // Loop through props and push to new object
  props.forEach((prop) => {
    picked[prop] = obj[prop];
  });

  // Return new object
  return picked;
};

export const omit = function <T>(obj: T, prop: keyof T): Omit<T, keyof T> {
  if (!obj) {
    return obj;
  }

  const newObj = { ...obj };
  delete newObj[prop];
  return newObj;
};
