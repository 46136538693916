import EventEmitter from "eventemitter3";
const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event, fn) => {
    return eventEmitter.on(event, fn);
  },
  once: (event, fn) => {
    return eventEmitter.once(event, fn);
  },
  off: (event, fn) => {
    return eventEmitter.off(event, fn);
  },
  emit: (event, payload) => {
    return eventEmitter.emit(event, payload);
  },
};

Object.freeze(Emitter);

export default Emitter;
