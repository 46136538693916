import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../redux/auth/auth.selectors";
import authTypes from "../redux/auth/auth.types";
import AuthenticationService from "../services/Authentication";
import moment from "moment";

const useAuthUser = () => {
  const dispatch = useDispatch();
  const { currentUser, isLoggedIn } = useSelector(authSelector);
  const [attemptedFetch, setAttemptedFetch] = useState(false);

  const getUser = async () => {
    try {
      const result = await AuthenticationService.authorizeUser();

      if (result && result.data && result.data.userInfo) {
        dispatch({
          type: authTypes.AUTH_SUCCESS,
          payload: {
            allowedClients: result.data.allowedClients,
            currentUser: {
              ...result.data.userInfo,
              lastLoginUtc: moment.utc().format(),
            },
          },
        });
      } else {
        // dispatch({ type: authTypes.AUTH_FAILURE });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // dispatch({ type: authTypes.AUTH_FAILURE });
    } finally {
      setAttemptedFetch(true);
    }
  };

  useEffect(() => {
    if (!isLoggedIn && !attemptedFetch) {
      getUser();
    }
  }, [isLoggedIn, attemptedFetch]);

  return { currentUser, isLoggedIn, attemptedFetch };
};

export default useAuthUser;
