import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import useAuthUser from "hooks/useAuthUser";

interface PrivateRouteProps extends RouteProps {
  component?: React.ComponentType<any>;
  render?: (props: any) => React.ReactNode;
  exact?: boolean;
  path: string | string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, render, ...rest }) => {
  const { isLoggedIn, attemptedFetch } = useAuthUser();

  const renderComponent = (props: any) => {
    if (isLoggedIn) {
      if (render) {
        return render(props);
      }

      if (Component) {
        return <Component {...props} />;
      }
    }

    // Redirect to login if not authenticated
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={renderComponent} />;
};

export default PrivateRoute;
