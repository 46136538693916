import { EuiProgress } from "@elastic/eui";
import { useConfig } from "./hooks/useConfig";
import Routes from "./routes";
import { AppInsightsContext, reactPlugin } from "./AppInsights";

const App = () => {
  const [isLoading] = useConfig();

  if (isLoading) return <EuiProgress size="xs" color="accent" position="fixed" />;

  // const onAuthRequired = () => {
  //   appInsights?.trackEvent({ name: "onAuthRequired" });
  //   history.push("/logout");
  // };

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Routes />
    </AppInsightsContext.Provider>
  );
};

export default App;
