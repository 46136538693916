import API from "../Classes/Api";
import { ApiGetCallParams } from "../Classes/ApiCallParams";

class ConfigService extends API {
  async getConfig() {
    const result = await super.call(
      new ApiGetCallParams({
        action: `config`,
        headers: { ...API.getAuthHeaders() },
      })
    );
    return result;
  }
}

const instance = new ConfigService();
export default instance;
