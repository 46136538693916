/* eslint-disable */
//as it sorting colors and chnages the charts color- we need to create an array of colors instead of object
import { faMoon, faSun, faSunrise, faSunset } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment-timezone";

export const COPYRIGHT_MESSAGE = `Copyright © ${new Date().getFullYear()} by Fortified`;
export const toastLifeTimeMs = 6000;
export const ErrorLoadingTableData = "Error loading table data.";
export const MAX_SELECTION_DAYS = 190;
export const DEFAULT_GROUP_BY_KEY = "N/A";
export const DEFAULT_PASS_FIELD = "********";
export const FontFamily = ` "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;
export const FD_CLIENT = 286;
export const DASHBOARD_DATE_PICKER_DAYS = 30;
export const TABLE_ROW_ID = "row_num";
export const DefaultPageSizeOptions = [5, 10, 15, 25, 50, 100];

export const DefaultLabel = "N/A";
export const DefaultValue = "-";
export const EmptyNumberPlaceholder = "- -";
export const DefaultAutoRefreshIntervalMilliseconds = 60000;
export const HOME_PAGE = "/home";
export const ONBOARDING_PAGE = "/account/onboarding";
export const DefaultDateFieldWith = "190px";
export const SupportEmail = "support@fortifiedinsight.com"; //todo: from env varables
export const SalesEmail = "sales@fortifiedinsight.com";
export const DocsUrl = "https://docs.fortifiedwisdom.com/";
export const TWO_DAYS_MS = 172800000;
export const ONE_WEEK_MS = 608400000;

export const TableFullScreenRowsPerPage = 15;

export const ExportHiddenClass = "export-hidden";

export const DAYS_MAP = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday",
};

export const TIME_OF_THE_DAY_MAP = {
  1: "ZeroToFour",
  2: "FourToEight",
  3: "EightToTwelve",
  4: "TwelveToSixteen",
  5: "SixteenToTwenty",
  6: "TwentyToZero",
};

export const TIME_OF_THE_DAY_MAPPING = {
  ZeroToFour: "0:00-4:00",
  FourToEight: "4:00-8:00",
  EightToTwelve: "8:00-12:00",
  TwelveToSixteen: "12:00-16:00",
  SixteenToTwenty: "16:00-20:00",
  TwentyToZero: "20:00-24:00",
};

export const TABLE_SORT_DIRECTION = {
  asc: "asc",
  desc: "desc",
};

export const API_SORT_DIRECTION = {
  asc: "Ascending",
  desc: "Descending",
};

export const SilencingWindowsState = [
  { id: 1, name: "Active" },
  { id: 2, name: "Future" },
  { id: 3, name: "Completed" },
];

export const CLIENT_STATUSES = [
  { id: 9, name: "Active" },
  { id: 13, name: "Inactive" },
  { id: 12, name: "Onboarding" },
];

export const SilencingStatus = Object.freeze({
  ActiveSilencingWindow: 1,
  FutureSilencingWindow: 2,
  NoSilencingWindowsScheduled: 0,
});

export const DAYS = [
  { id: "1", name: "Sunday", short: "Su" },
  { id: "2", name: "Monday", short: "Mo" },
  { id: "3", name: "Tuesday", short: "Tu" },
  { id: "4", name: "Wednesday", short: "We" },
  { id: "5", name: "Thursday", short: "Th" },
  { id: "6", name: "Friday", short: "Fr" },
  { id: "7", name: "Saturday", short: "Sa" },
];

export const NEGATE_FILTER = "|||||";

const EnumTextFilterActions = {
  //StartsWith: "1",
  Contains: "2",
  Missing: "3",
};

export const DefaultEnumTextFilterAction = EnumTextFilterActions.Contains;

export const SelectEmptyValue = "";

function populateTimes(interval = 60) {
  let hours;
  let minutes;
  let ampm;

  const result: any[] = [];

  for (let i = 0; i < 1440; i += interval) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`; // adding leading zero
    }
    ampm = hours % 24 < 12 ? "AM" : "PM";
    hours %= 12;

    if (hours === 0) {
      hours = 12;
    }

    result.push({ id: hours, name: `${hours}:${minutes} ${ampm}` });
  }

  return result;
}
export const TIMES = populateTimes();

export const PLATFORMS = [
  { id: 1, name: "Microsoft SQL Server" },
  { id: 2, name: "Microsoft Windows Machine" },
  { id: 3, name: "Oracle Database" },
];

export const SHORTEN_PLATFORMS = [
  { display: "SQL Server", id: 1, name: "Microsoft SQL Server" },
  { display: "Windows", id: 2, name: "Microsoft Windows Machine" },
  { display: "Oracle", id: 3, name: "Oracle Database" },
];

export const ALERT_PRIORITIES = ["P0/P1", "P2"];

export const MIN_DASHBOARD_ROW_HEIGHT = 230;

export const DASHBOARD_MAIN_CHART_HEIGHT = 350;

export const LOCAL_TZ = "[Browser]";

export const US_TIME_ZONES_TO_GENERIC = {
  "America/Anchorage": { longAbbrv: "Alaska Time", shortAbbrv: "AKT" },
  "America/Chicago": { longAbbrv: "Central Time", shortAbbrv: "CT" },
  "America/Denver": { longAbbrv: "Mountain Time", shortAbbrv: "MT" },
  "America/Los_Angeles": { longAbbrv: "Pacific Time", shortAbbrv: "PT" },
  "America/New_York": { longAbbrv: "Eastern Time", shortAbbrv: "ET" },
  "Asia/Jerusalem": { longAbbrv: "Israel Time", shortAbbrv: "IST" },
  "Europe/London": { longAbbrv: "GMT", shortAbbrv: "GMT" },
  "Pacific/Honolulu": { longAbbrv: "Hawaii-Aleutian Time", shortAbbrv: "HST" },
};

export const US_TIME_ZONES = Object.keys(US_TIME_ZONES_TO_GENERIC);

export const US_TIMEZONES_ABBR_MAPPER = {
  AKT: { timeZone: "America/Anchorage" },
  CT: { timeZone: "America/Chicago" },
  ET: { timeZone: "America/New_York" },
  GMT: { timeZone: "Europe/London" },
  HST: { timeZone: "Pacific/Honolulu" },
  IST: { timeZone: "Asia/Jerusalem" },
  MT: { timeZone: "America/Denver" },
  PT: { timeZone: "America/Los_Angeles" },
};

export const COLORS = Object.freeze({
  $brandGreen: "#14a05a",
  $euiColorVis1Blue: "#6092c0",
  $brandGreen2: "#A9D8CB",
  $euiColorVis2DarkPink: "#d36086",
  $euiColorVis0_behindText: "#6DCCB1",
  $euiColorVis3Purple: "#9170bb",
  $euiColorVis1_behindText: "#79AAD9",
  $euiColorVis4Pink: "#ca8eae",
  $euiColorVis2_behindText: "#EE789D",
  $euiColorVis5Yellow: "#d6bf57",
  $euiColorVis3_behindText: "#A987D1",
  $euiColorVis6: "#b9a888",
  $euiColorVis0Green: "#54b399",
  $euiColorVis7Orange: "#da8b45",
  $euiColorPrimary: "#006BB4",
  $euiColorVis8: "#aa6556",
  $euiColorLightestShade: "#F5F7FA",
  $euiColorVis9DarkOrange: "#e7664c",
  $euiColorLightShade: "#D3DAE6",
  $euiColorYellow: "#FFC700",
  $euiColorDarkShade: "#69707d",
  $euiRedColorAnomalies: "#BD271E",
  $euiColorDarkestShade: "#343741",
  $euiColorVis4_behindText: "#E4A6C7",
  $chartOrange: "#f5a700",
  $euiColorVis5_behindText: "#F1D86F",
  $chartGreen: "#54b399",
  $euiColorVis6_behindText: "#D2C0A0",
  $darkGrey: "#343741",
  $euiColorVis7_behindText: "#F5A35C",
  $blue: "#016BB4",
  $euiColorVis8_behindText: "#C47C6C",
  $darkGrey2: "#55566e",
  $euiColorVis9_behindText: "#FF7E62",
  $euiColorDanger: "#bd271e",
  $euiColorSecondary: "#017d73",
  $euiColorMediumShade: "#98A2B3",
  $healthy: "#017d73",
  $euiColorWarning: "#f5a700",
  $activityInstance: "#DCE9F3",
  $lightBlue: "#1B8DCD",
  $chartGreen1: "#4A9D61",
  $lightGrey: "#DEE2EA",
  $linkBlue: "#0071c2",
  $medGrey: "#eceff4",
  $progressBehindTestBlue: "#79aad966",
  $progressBehindTestGreen: "#6dccb166",
  $progressBehindTestRed: "#ee789d66",
  $series1: "#0077CC",
  $appBackground: "#f6f7fb",
  $text: "#343740",
  $series2: "#F04E98",
  $textDisabled: "#dde1e8",
  $subdued: "$6a717d",
  $unhealthy: "#ca0000",
  $title: "#1a1c21",
  $trendShadesGreen: "#E3F4EB",
  $trendShadesRed: "#F7E5E4",
  $trendShadesYellow: "#FEF4E0",
});

export const COLORS_3_STEPS_HEALTH = [
  COLORS.$euiColorVis0Green, //green
  COLORS.$euiColorWarning, // orange
  COLORS.$euiColorDanger, //red
];

export const COLORS_5_STEPS_HEALTH = [
  COLORS.$euiColorVis0Green,
  COLORS.$euiColorVis1Blue,
  COLORS.$euiColorYellow,
  COLORS.$euiColorWarning,
  COLORS.$euiColorDanger,
];

export const COLORS_ARR = Object.values(COLORS);

export const TIME_FRAMES = [
  {
    hours: 4,
    icon: faSunrise,
    iconColor: COLORS.$euiColorVis5Yellow,
    id: "1",
    name: "0:00-4:00",
    short: "0-4",
    startHour: 0,
  },
  {
    hours: 4,
    icon: faSun,
    iconColor: COLORS.$euiColorVis5Yellow,
    id: "2",
    name: "4:00-8:00",
    short: "4-8",
    startHour: 4,
  },
  {
    hours: 4,
    icon: faSun,
    iconColor: COLORS.$euiColorVis7Orange,
    id: "3",
    name: "8:00-12:00",
    short: "8-12",
    startHour: 8,
  },
  {
    hours: 4,
    icon: faSunset,
    iconColor: COLORS.$euiColorVis7Orange,
    id: "4",
    name: "12:00-16:00",
    short: "12-16",
    startHour: 12,
  },
  {
    hours: 4,
    icon: faMoon,
    iconColor: COLORS.$euiColorVis1Blue,
    id: "5",
    name: "16:00-20:00",
    short: "16-20",
    startHour: 16,
  },
  {
    hours: 4,
    icon: faMoon,
    iconColor: COLORS.$euiColorVis1Blue,
    id: "6",
    name: "20:00-24:00",
    short: "20-24",
    startHour: 20,
  },
];
export const HoursToTimeFrameLookup = {
  0: TIME_FRAMES[0],
  1: TIME_FRAMES[0],
  10: TIME_FRAMES[2],
  11: TIME_FRAMES[2],

  12: TIME_FRAMES[3],
  13: TIME_FRAMES[3],
  2: TIME_FRAMES[0],
  14: TIME_FRAMES[3],

  3: TIME_FRAMES[0],
  15: TIME_FRAMES[3],
  4: TIME_FRAMES[1],
  16: TIME_FRAMES[4],

  5: TIME_FRAMES[1],
  17: TIME_FRAMES[4],
  6: TIME_FRAMES[1],
  18: TIME_FRAMES[4],

  7: TIME_FRAMES[1],
  19: TIME_FRAMES[4],
  8: TIME_FRAMES[2],
  20: TIME_FRAMES[5],

  9: TIME_FRAMES[2],
  21: TIME_FRAMES[5],
  22: TIME_FRAMES[5],
  23: TIME_FRAMES[5],
};

export const DefaultDates = {
  end: "now",
  start: "now-7d",
  version: 1,
};

const isRageSmallerThanInterval = (start, end, intervalMinutes) => {
  // console.log("isRageSmallerThanInterval - start end--", start?._i, end?._i, intervalMinutes)
  const diffMinutes = end.diff(start, "minutes");
  // console.log("isRageSmallerThanInterval - diff--", diffMinutes, diffMinutes < intervalMinutes)
  return diffMinutes < intervalMinutes;
};

export const AggregationIntervals = [
  //todo: move to consts
  {
    id: "Auto",
    isAllowed: (start, end) => true,
    label: "Auto", //IsHistogramIntervalValidForTimeRange on server side
  },
  {
    id: "1m",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 1)) return false;
      const hours = end.diff(start, "hours");
      const moreThan30DaysBack = moment().diff(start, "days") > 30;
      return hours <= 3 && !moreThan30DaysBack;
    },
    label: "1min",
  },
  {
    id: "5m",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 5)) return false;
      const hours = end.diff(start, "hours");
      const moreThan30DaysBack = moment().diff(start, "days") > 30;
      return hours <= 24 && !moreThan30DaysBack;
    },
    label: "5min",
  },
  {
    id: "15m",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 15)) return false;
      const days = end.diff(start, "days");
      return days <= 3;
    },
    label: "15min",
  },
  {
    id: "1h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 60)) return false;
      const hours = end.diff(start, "hours");
      const days = end.diff(start, "days");
      return days <= 20 && hours >= 3; //hours <= 30 * 24 && hours >= 3;
    },
    label: "1h",
    valueInMinutes: 60,
  },
  {
    id: "3h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 3 * 60)) return false;
      const hours = end.diff(start, "hours");
      const days = end.diff(start, "days");
      return days <= 45 && hours >= 3;
    },
    label: "3h",
  },
  {
    id: "6h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 6 * 60)) return false;
      const hours = end.diff(start, "hours");
      const days = end.diff(start, "days");
      return days <= 60 && hours >= 6;
    },
    label: "6h",
  },
  {
    id: "12h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 12 * 60)) return false;
      const hours = end.diff(start, "hours");
      return hours < 90 * 24 && hours >= 12;
    },
    label: "12h",
    totalHours: 12,
  },
  {
    id: "1d",
    isAllowed: (start, end) => {
      if (isRageSmallerThanInterval(start, end, 24 * 60)) return false;
      const moreThanInterval = moment().diff(start, "days") >= 1;
      return moreThanInterval;
    },
    label: "1d", //IsHistogramIntervalValidForTimeRange on server side
  },
  {
    id: "7d",
    isAllowed: (start, end) => {
      if (isRageSmallerThanInterval(start, end, 7 * 24 * 60)) return false;
      const moreThanInterval = moment().diff(start, "days") >= 7;
      return moreThanInterval;
    },
    label: "7d", //IsHistogramIntervalValidForTimeRange on server side
  },
  {
    id: "30d",
    isAllowed: (start, end) => {
      if (isRageSmallerThanInterval(start, end, 30 * 24 * 60)) return false;
      const moreThanInterval = moment().diff(start, "days") >= 30;
      const days = end.diff(start, "days");
      return days >= 61 && moreThanInterval;
    },
    label: "30d", //IsHistogramIntervalValidForTimeRange on server side
  },
];

export const USTimezoneColors = Object.freeze({
  CST: "#FFFF99",
  EST: "#FFCC99",
  MST: "#CCFFCC",
  PST: "#B2E9F7",
});

export const AllStates = [
  { color: USTimezoneColors.CST, id: "AL", val: "01" },
  { color: USTimezoneColors.EST, id: "AS", val: "60" },
  { color: USTimezoneColors.MST, id: "AZ", val: "04" },
  { color: USTimezoneColors.CST, id: "AR", val: "05" },
  { color: USTimezoneColors.PST, id: "CA", val: "06" },
  { color: USTimezoneColors.MST, id: "CO", val: "08" },
  { color: USTimezoneColors.EST, id: "CT", val: "09" },
  { color: USTimezoneColors.EST, id: "DE", val: "10" },
  { color: USTimezoneColors.EST, id: "DC", val: "11" },
  { color: USTimezoneColors.EST, id: "FL", val: "12" },
  { color: USTimezoneColors.EST, id: "FM", val: "64" },
  { color: USTimezoneColors.EST, id: "GA", val: "13" },
  { color: USTimezoneColors.EST, id: "GU", val: "66" },
  { color: USTimezoneColors.MST, id: "ID", val: "16" },
  { color: USTimezoneColors.CST, id: "IL", val: "17" },
  { color: USTimezoneColors.EST, id: "IN", val: "18" },
  { color: USTimezoneColors.CST, id: "IA", val: "19" },
  { color: USTimezoneColors.CST, id: "KS", val: "20" },
  { color: USTimezoneColors.EST, id: "KY", val: "21" },
  { color: USTimezoneColors.CST, id: "LA", val: "22" },
  { color: USTimezoneColors.EST, id: "ME", val: "23" },
  { color: USTimezoneColors.EST, id: "MH", val: "68" },
  { color: USTimezoneColors.EST, id: "MD", val: "24" },
  { color: USTimezoneColors.EST, id: "MA", val: "25" },
  { color: USTimezoneColors.EST, id: "MI", val: "26" },
  { color: USTimezoneColors.CST, id: "MN", val: "27" },
  { color: USTimezoneColors.CST, id: "MS", val: "28" },
  { color: USTimezoneColors.CST, id: "MO", val: "29" },
  { color: USTimezoneColors.MST, id: "MT", val: "30" },
  { color: USTimezoneColors.CST, id: "NE", val: "31" },
  { color: USTimezoneColors.PST, id: "NV", val: "32" },
  { color: USTimezoneColors.EST, id: "NH", val: "33" },
  { color: USTimezoneColors.EST, id: "NJ", val: "34" },
  { color: USTimezoneColors.MST, id: "NM", val: "35" },
  { color: USTimezoneColors.EST, id: "NY", val: "36" },
  { color: USTimezoneColors.EST, id: "NC", val: "37" },
  { color: USTimezoneColors.CST, id: "ND", val: "38" },
  { color: USTimezoneColors.EST, id: "MP", val: "69" },
  { color: USTimezoneColors.EST, id: "OH", val: "39" },
  { color: USTimezoneColors.CST, id: "OK", val: "40" },
  { color: USTimezoneColors.PST, id: "OR", val: "41" },
  { color: USTimezoneColors.EST, id: "PW", val: "70" },
  { color: USTimezoneColors.EST, id: "PA", val: "42" },
  { color: USTimezoneColors.EST, id: "PR", val: "72" },
  { color: USTimezoneColors.EST, id: "RI", val: "44" },
  { color: USTimezoneColors.EST, id: "SC", val: "45" },
  { color: USTimezoneColors.CST, id: "SD", val: "46" },
  { color: USTimezoneColors.CST, id: "TN", val: "47" },
  { color: USTimezoneColors.CST, id: "TX", val: "48" },
  { color: USTimezoneColors.EST, id: "UM", val: "74" },
  { color: USTimezoneColors.MST, id: "UT", val: "49" },
  { color: USTimezoneColors.EST, id: "VT", val: "50" },
  { color: USTimezoneColors.EST, id: "VA", val: "51" },
  { color: USTimezoneColors.EST, id: "VI", val: "78" },
  { color: USTimezoneColors.PST, id: "WA", val: "53" },
  { color: USTimezoneColors.EST, id: "WV", val: "54" },
  { color: USTimezoneColors.CST, id: "WI", val: "55" },
  { color: USTimezoneColors.MST, id: "WY", val: "56" },
];

export const PatchStatus = Object.freeze({
  Bad: 3,
  OK: 1,
  Warning: 2,
});

export enum Subjects {
  Assessment = "Assessment",
  Alerts_Dashboard = "Alerts Dashboard",
  Application_Dashboards = "Application Dashboards",
  Availability_Groups_Dashboard = "Availability Groups Dashboard",
  Billing = "Billing",
  Blocking_Dashboard = "Blocking Dashboard",
  Client_Connections_Dashboard = "Client Connections Dashboard",
  Client_Information = "Client Information",
  Client_MSP_Information = "Client MSP Information",
  Client_Onboarding = "Client Onboarding",
  Collector_Health_Admin = "Collector Health - Admin",
  Collector_Health_Client = "Collector Health - Client",
  Database_Dashboards = "Database Dashboards",
  Deadlocks_Dashboard = "Deadlocks Dashboard",
  Environment_Management = "Environment Management",
  FindingsViewAll = "Findings - View All", //do not change
  Fortified_Support = "Fortified Support",
  Host_Dashboards = "Host Dashboards",
  Instance_Dashboards = "Instance Dashboards",
  Job_Dashboards = "Job Dashboards",
  Maintenance_Task_Management = "Maintenance Task Management",
  Metrics_Dashboard = "Metrics Dashboard",
  Monitoring_Management = "Monitoring Management",
  Query_Statistics_Dashboard = "Query Statistics Dashboard",
  SQL_Activity_Dashboard = "SQL Activity Dashboard",
  SQL_Audit_Dashboard = "SQL Audit Dashboard",
  Security_Dashboard = "Security Dashboard",
  User_Management = "User Management",
  Index_Dashboards = "Index Dashboards",
  Cloud_Migration_Assessment = "Cloud Migration Assessment",
  AskWisdom = "Ask WISdom",
  Tagging = "Tagging",
  V2_Screens = "V2 Screens",
}

//todo: union
export enum Roles {
  Admin = "Admin",
  Read = "Read",
  Write = "Write",
}

export const MAX_PAGE_WIDTH = 1920;

export const PIE_OPTIONS = {
  cpu: "cpu",
  io: "io",
  cost: "cost",
};

// export const EmptyClientID = -99;

// export const EmptyAPIResponse = { data: [] };

export const STATUS = {
  open: "Open",
  closed: "Closed",
  ignored: "Ignored",
  progress: "In Progress",
  pending: "Pending Validation",
};

export const IMPACT_TYPE_COLORS = {
  Security: "#c4dfa5",
  Availability: "#addfdb",
  Performance: "#a1cede",
  "Operational Efficiency": "#f6d487",
  Stability: "#f7bd87",
  Capacity: "#D2C0A0",
  Management: "#6DCCB1",
};

export const APP_STATUS = {
  down: "down",
  offline: "offline",
  healthy: "healthy",
};

export const TABLE_CHIPS_COLORS = {
  0: "#c4dfa5",
  1: "#addfdb",
  2: "#f6d487",
  3: "#f7bd87",
  4: "#a1cede",
  5: "#D2C0A0",
  6: "#6DCCB1",
};

export const countByHour: any = {
  ZeroToFour: {
    icon: {
      prefix: "far",
      iconName: "sunrise",
      icon: [
        576,
        512,
        [127749],
        "f766",
        "M271 7c9.4-9.4 24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-39-39V200c0 13.3-10.7 24-24 24s-24-10.7-24-24V81.9l-39 39c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L271 7zM393.3 225.8c7.5 3.1 12.9 9.8 14.4 17.8l18.1 98.5 98.5 18.1c8 1.5 14.7 6.9 17.8 14.4s2.2 16.1-2.4 22.8L493.9 464H552c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H82.1L36.2 397.5c-4.6-6.7-5.5-15.3-2.4-22.8s9.8-13 17.8-14.4l98.5-18.1 18.1-98.5c1.5-8 6.9-14.7 14.4-17.8s16.1-2.2 22.8 2.4L288 285.1l82.5-56.9c6.7-4.6 15.3-5.5 22.8-2.4zM140.4 464h36.7c7.8-54.3 54.4-96 110.9-96s103.1 41.7 110.9 96h36.7l43.6-63.2-78.3-14.4c-9.8-1.8-17.5-9.5-19.3-19.3l-14.4-78.3L301.6 334c-8.2 5.7-19 5.7-27.2 0l-65.6-45.2-14.4 78.3c-1.8 9.8-9.5 17.5-19.3 19.3L96.8 400.8 140.4 464zM350 464c-7.1-27.6-32.2-48-62-48s-54.9 20.4-62 48H350z",
      ],
    },
    iconColor: "#d6bf57",
    key: "0:00-4:00",
    shortKey: "0-4",
  },
  FourToEight: {
    icon: {
      prefix: "far",
      iconName: "sun",
      icon: [
        512,
        512,
        [9728],
        "f185",
        "M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z",
      ],
    },
    iconColor: "#d6bf57",
    key: "4:00-8:00",
    shortKey: "4-8",
  },
  EightToTwelve: {
    icon: {
      prefix: "far",
      iconName: "sun",
      icon: [
        512,
        512,
        [9728],
        "f185",
        "M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z",
      ],
    },
    iconColor: "#da8b45",
    key: "8:00-12:00",
    shortKey: "8-12",
  },
  TwelveToSixteen: {
    icon: {
      prefix: "far",
      iconName: "sunset",
      icon: [
        576,
        512,
        [127751],
        "f767",
        "M305 217c-9.4 9.4-24.6 9.4-33.9 0l-80-80c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l39 39V24c0-13.3 10.7-24 24-24s24 10.7 24 24V142.1l39-39c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-80 80zm88.3 8.8c7.5 3.1 12.9 9.8 14.4 17.8l18.1 98.5 98.5 18.1c8 1.5 14.7 6.9 17.8 14.4s2.2 16.1-2.4 22.8L493.9 464H552c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H82.1L36.2 397.5c-4.6-6.7-5.5-15.3-2.4-22.8s9.8-13 17.8-14.4l98.5-18.1 18.1-98.5c1.5-8 6.9-14.7 14.4-17.8s16.1-2.2 22.8 2.4L288 285.1l82.5-56.9c6.7-4.6 15.3-5.5 22.8-2.4zM140.4 464h36.7c7.8-54.3 54.4-96 110.9-96s103.1 41.7 110.9 96h36.7l43.6-63.2-78.3-14.4c-9.8-1.8-17.5-9.5-19.3-19.3l-14.4-78.3L301.6 334c-8.2 5.7-19 5.7-27.2 0l-65.6-45.2-14.4 78.3c-1.8 9.8-9.5 17.5-19.3 19.3L96.8 400.8 140.4 464zM350 464c-7.1-27.6-32.2-48-62-48s-54.9 20.4-62 48H350z",
      ],
    },
    iconColor: "#da8b45",
    key: "12:00-16:00",
    shortKey: "12-16",
  },
  SixteenToTwenty: {
    icon: {
      prefix: "far",
      iconName: "moon",
      icon: [
        384,
        512,
        [127769, 9214],
        "f186",
        "M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z",
      ],
    },
    iconColor: "#6092c0",
    key: "16:00-20:00",
    shortKey: "16-20",
  },
  TwentyToZero: {
    icon: {
      prefix: "far",
      iconName: "moon",
      icon: [
        384,
        512,
        [127769, 9214],
        "f186",
        "M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z",
      ],
    },
    iconColor: "#6092c0",
    key: "20:00-24:00",
    shortKey: "20-24",
  },
};

export const auto3hInterval = "03:00:00";

export const AUTH_API_PATH = "authentications";

export const MINUTES_PER_DAY = 60 * 24;
export const SEVEN_DAYS = 7;
export const DEFAULT_DAYS = 30;
export const FULL_DATE_FORMAT = "YYYY-MM-DD[T]HH:mm:ss"; // format of dates accepted from server
export const DAY_FORMAT = "YYYY-MM-DD";
export const DATE_PICKER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const TABLE_FORMAT_FULL = "YYYY MMM D, HH:mm:ss z";
export const TABLE_FORMAT_MINUTES = "MMM D, HH:mm z";
export const TABLE_FORMAT_MINUTES_SERVER_TIME = "MMM D, HH:mm";
export const TABLE_FORMAT_NO_TZ = "YYYY MMM D, HH:mm:ss";
export const CHART_HEADER_FORMAT_NO_TZ = "YYYY MMM D, HH:mm";
export const CHART_HEADER_FORMAT_DAYS_NO_TZ = "YYYY MMM D,dddd- HH:mm";
export const CHART_HEADER_FORMAT_TZ = "YYYY MMM D, HH:mm z";
export const CHART_HEADER_FORMAT_DAY_ONLY = "YYYY MMM D";

// previously ChangeDiffUnknown
export const CHANGE_DIFF_UNKNOWN = 100_000_000;
