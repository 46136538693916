import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import apiReducer from "./api/api.reducer";
import authReducer from "./auth/auth.reducer";
import globalFilterReducer from "./globalFilter/globalFilter.reducer";

const rootPersistConfig = {
  key: "root",
  storage: sessionStorage, // store per tab
  blacklist: ["auth"],
};

const authPersistConfig = {
  key: "auth",
  storage: sessionStorage,
  blacklist: ["somethingTemporary"],
};

const rootReducer: any = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  api: apiReducer,
  globalFilter: globalFilterReducer,
});

const middlewares = []; //  [logger];

const store = createStore(
  persistReducer(rootPersistConfig, rootReducer),
  applyMiddleware(...middlewares)
);

const persistor = persistStore(store);

export { store, persistor };
