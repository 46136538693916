import { Roles } from "Consts";
import { store } from "../redux/store";

export const isSubjectAuthorizedForUser = ({
  subject,
  role,
}: {
  role: "Read" | "Write" | "Admin" | Roles;
  subject: string;
}): boolean => {
  const state = store.getState();
  const {
    auth: { permissions },
  }: any = state;

  if (permissions && permissions[subject]) {
    return permissions[subject]?.Roles.includes(role);
  }
  return false;
};

export const GetFormCellAuthorization = (permissionGroup) => {
  return isSubjectAuthorizedForUser(permissionGroup);
};

export const isLoggedIn = (s: any) => {
  return s.currentUser && !!s.currentUser.userID;
};
