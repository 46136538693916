import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { EuiProvider } from "@elastic/eui";
import createCache from "@emotion/cache";
import "@elastic/eui/dist/eui_theme_light.css";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./redux/store";
import "./index.css";

const cache = createCache({
  key: "wis",
  container: document.querySelector('meta[name="global-style-insert"]'),
});

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <EuiProvider cache={cache} colorMode="light">
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </EuiProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
