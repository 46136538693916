/**https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin */

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { APPLICATION_INSIGHTS_INSTRUMENTATION_KEY } from "config";

let appInsights = null;
let reactPlugin = null;
let browserHistory = null;

const initializeAppInsights = () => {
  browserHistory = createBrowserHistory({ basename: "" });
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        // Add any other extension configs here
      },
      extensions: [reactPlugin],
      instrumentationKey:
        localStorage.getItem("appInsightsInstrumentationKey") ||
        APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    },
  });
  appInsights.loadAppInsights();
};

export { initializeAppInsights, reactPlugin, appInsights, AppInsightsContext };
