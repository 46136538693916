import { APIActionTypes } from "./api.types";

// export const setIsLoading = isLoading => ({
//     type: APIActionTypes.SET_IS_LOADING,
//     payload: isLoading
// });

export const setIsDownloading = (isDownloading) => ({
  type: APIActionTypes.SET_IS_Downloading,
  payload: isDownloading,
});

export const setSignalRconnection = (connection) => ({
  type: APIActionTypes.SET_SINGNALR_CONNECTION,
  payload: connection,
});
