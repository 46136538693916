import { APIActionTypes } from "./api.types";

const INITIAL_STATE = {
  isLoading: false,
  isDownloading: false,
  rConnection: null,
};

const apiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APIActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case APIActionTypes.SET_IS_Downloading:
      return {
        ...state,
        isDownloading: action.payload,
      };
    case APIActionTypes.SET_SINGNALR_CONNECTION:
      return {
        ...state,
        rConnection: action.payload,
      };
    default:
      return state;
  }
};

export default apiReducer;
