import { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router";
import { EuiProgress } from "@elastic/eui";
import { store } from "./redux/store";
import "./components/css/colors.css";
const LoginContainer = lazy(() => import("./pages/Login/Login"));
const AdminContainer = lazy(() => import("./containers/AdminContainer"));
const AuthenticatedContainer = lazy(() => import("./containers/AuthenticatedContainer"));
const EstablishPasswordContainer = lazy(() => import("./containers/EstablishPasswordContainer"));
const Logout = lazy(() => import("./pages/Logout/Logout"));
const NotFound = lazy(() => import("./components/NotFound"));
const Unauthorized = lazy(() => import("./pages/Unauthorized"));
const UnavailableContainer = lazy(() => import("./components/Unavailable"));

import { HOME_PAGE } from "Consts";
import PrivateRoute from "components/Auth/PrivateRoute";

const Routes = (props) => {
  return (
    <Suspense fallback={<EuiProgress color="accent" size="xs" />}>
      <Switch>
        {/* <Route component={LoginCallback} path="/implicit/callback" /> */}
        <Route exact path="/login" render={(props) => <LoginContainer {...props} />} />
        <Route component={EstablishPasswordContainer} path="/confirmations/:confirmationToken" />
        <Route component={EstablishPasswordContainer} path="/forgot_password/:passwordResetToken" />

        <PrivateRoute
          exact
          path="/"
          render={(props) => {
            return <Redirect to={HOME_PAGE} />;
            // return isLoggedInRequired(props.location) ?? <Redirect to={HOME_PAGE} />;
          }}
        />

        <PrivateRoute
          path={[
            "/home",
            "/home_v2",
            "/ask_wisdom",
            "/monitoring",
            "/environment",
            "/inventory",
            "/database",
            "/compliance",
            "/account",
            "/assessment",
          ]}
          render={(props) => {
            // const loginRedirect = isLoggedInRequired(props.location);

            // if (loginRedirect) {
            //   return loginRedirect;
            // }
            return <AuthenticatedContainer />;
          }}
        />

        <PrivateRoute
          path="/admin"
          render={(props) => {
            // const loginRedirect = isLoggedInRequired(props.location);

            // if (loginRedirect) {
            //   return loginRedirect;
            // }

            const state = store.getState();
            const { auth }: any = state;
            const { currentUser } = auth;

            if (/^Portal/.test(currentUser?.roleName ?? "")) {
              return (
                <Redirect
                  to={{
                    pathname: "/forbidden",
                    state: { from: props.location },
                  }}
                />
              );
            }
            return <AdminContainer />;
          }}
        />
        <Route
          exact
          path="/reset_password"
          render={(props) => <LoginContainer resetPassword={1} {...props} />}
        />

        <Route component={Unauthorized} path="/forbidden" />
        <Route component={UnavailableContainer} path="/unavailable" />
        <Route
          path="/logout"
          render={(props) => {
            //debugger;
            const logoutProps = {
              Message: props?.location?.state?.msg,
              from: window.location.href,
              origin: "route.js",
            };
            return <Logout {...logoutProps} />;
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
