import ActionTypes from "./auth.types";

export const logout = () => ({
  type: ActionTypes.LOGOUT,
});

export const setPermissions = (permissions) => ({
  type: ActionTypes.PERMISSIONS,
  payload: permissions,
});

export const setAllowedClients = (clients) => ({
  type: ActionTypes.ALLOWED_CLIENTS,
  payload: clients,
});
