import API from "../Classes/Api";
import { ApiGetCallParams, ApiPatchCallParams, ApiPostCallParams } from "../Classes/ApiCallParams";
import { logout } from "../redux/auth/auth.actions";

class Authentication extends API {
  async signEULA(userId: number) {
    const result = await super.call(
      new ApiPostCallParams({
        action: `users/${userId}/eula/accept`,
        headers: { ...API.getAuthHeaders() },
        body: {},
      })
    );

    return result;
  }

  async getAllowedClients() {
    const result = await super.call(
      new ApiGetCallParams({
        action: "user/allowed_clients",
        headers: { ...API.getAuthHeaders() },
      })
    );

    return result;
  }

  async getCurrentUserInfo() {
    try {
      const authHeaders = API.getAuthHeaders();
      const response = await super.call(
        new ApiGetCallParams({
          action: "authentications/me",
          headers: { ...authHeaders },
        })
      );

      if (response.data && response.data.userID) {
        return response;
      }
      logout();
      return null;
    } catch (error) {
      return null;
    }
  }

  async updateAccount(user: any) {
    const response = await super.call(
      new ApiPatchCallParams({
        action: "authentications/me",
        headers: { ...API.getAuthHeaders() },
        body: { data: user },
      })
    );

    return response;
  }

  async startResetPassword(email: any) {
    const data = { emailAddress: email };
    const result = await super.call(
      new ApiPostCallParams({
        action: `passwords/reset`,
        body: { data }, // api looks for body.data
        headers: { ...API.getAuthHeaders() },
      })
    );

    return result;
  }

  async finalizeResetPassword(payload: {
    password: string;
    passwordConfirmation: string;
    passwordResetToken: any;
  }) {
    const data = { ...payload };

    const response = await super.call(
      new ApiPatchCallParams({
        action: `passwords/reset`,
        headers: { ...API.getAuthHeaders() },
        body: { data },
      })
    );

    return response;
  }

  async createPassword(payload: {
    confirmationToken: any;
    password: string;
    passwordConfirmation: string;
  }) {
    const data = { ...payload };
    const result = await super.call(
      new ApiPostCallParams({
        action: `passwords`,
        body: { data }, // api looks for body.data
        headers: { ...API.getAuthHeaders() },
      })
    );

    return result;
  }

  async pingServer() {
    try {
      const response = await super.call(
        new ApiGetCallParams({
          action: "ping",
          headers: { ...API.getAuthHeaders() },
        })
      );

      return response;
    } catch (error: any) {
      console.log("There has been a problem with the fetch update operation: ", error.message);
      return null;
    }
  }

  async login({ email }: { email: string }) {
    const result = await super.call(
      new ApiGetCallParams({
        action: `auth/login`,
        query: { email },
        headers: { ...API.getAuthHeaders() },
      })
    );

    return result;
  }

  async authorizeUser() {
    const result = await super.call(
      new ApiPostCallParams({
        action: "authentications",
        headers: { ...API.getAuthHeaders() },
      })
    );

    return result;
  }

  async logoutUser() {
    const result = await super.call(
      new ApiGetCallParams({
        action: "auth/logout",
        headers: { ...API.getAuthHeaders() },
      })
    );

    return result;
  }
}

const instance = new Authentication();

export default instance;
